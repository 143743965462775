/*jshint esversion: 6 */

import $ from 'jquery';

const SELECTORS = {
  ACCORDION: '.accordion',
  ITEM: '.accordion__item',
  ITEM_HEADER: '>.accordion__item__header',
  ITEM_HEADER_BTN: '.accordion__item__header__btn',
  ITEM_OPEN: 'accordion__item--open',
  ITEM_BODY: '>.accordion__item__body'
};

function accordion() {
  $(SELECTORS.ACCORDION).each((index, element) => {
    let $accordion = $(element);
    initAccordion($accordion);
  });
}

function initAccordion($accordion) {
  const $items = [];
  $accordion.find(SELECTORS.ITEM).each((index, element) => {
    $items.push(initAccordionItem($(element)), $items);
  });
}

function initAccordionItem($item, $items) {
  let $header = $item.find(SELECTORS.ITEM_HEADER);
  $header.on('click', event => {
    if ($item.hasClass(SELECTORS.ITEM_OPEN)) {
      closeItem($item, $items);
    } else {
      openItem($item, $items);
    }
  });
}

function openItem($item, $items) {
  $item.addClass(SELECTORS.ITEM_OPEN);
  $item.find(SELECTORS.ITEM_BODY).slideDown(100);
  $item.find(SELECTORS.ITEM_HEADER).find(SELECTORS.ITEM_HEADER_BTN).data('ariaExpanded', true);
}

function closeItem($item, $items) {
  $item.removeClass(SELECTORS.ITEM_OPEN);
  $item.find(SELECTORS.ITEM_BODY).slideUp(100);
  $item.find(SELECTORS.ITEM_HEADER).find(SELECTORS.ITEM_HEADER_BTN).data('ariaExpanded', false);
}

export default accordion;