// imports
import $ from "jquery";
import header from "./header";
import heroSlider from "./hero-slider";
import productSingleSlider from "./product-single-slider";
import productRelatedSlider from "./product-related-slider";
import accordion from "./accordion";
import productFinder from "./product-finder";
import newsTeaser from "./news-teaser";
import productTeaser from "./product-teaser";
import imageTeaser from "./image-teaser";
import productFavorites from "./product-favorites";
import productSingle from "./product-single";
import productLightbox from "./product-lightbox";
import cookie from "./cookie";
import productFormatValue from "./product-format-value";
import ProductOverview from "./product-overview";

document.addEventListener("DOMContentLoaded", (event) => {
  header();
  heroSlider();
  productSingleSlider();
  productRelatedSlider();
  accordion();
  productFinder();
  newsTeaser();
  productTeaser();
  imageTeaser();
  productFavorites();
  productSingle();
  productLightbox();
  cookie();
  productFormatValue();
});

$(document).ready(function () {
  var body = $("body");
  var scroll = $(window).scrollTop();

  if (scroll >= 50) {
    body.removeClass("is-not-scrolled").addClass("is-scrolled");
  } else {
    body.removeClass("is-scrolled").addClass("is-not-scrolled");
  }
  $("#toggle-mobile-menu, #also-toggle-mobile-menu").click(function () {
    $("body").toggleClass("navigation-open");
  });

  // Populate Megamenu from hidden header submenus
  $("#menu-main-de li a, #menu-sub-de li a").click(function (event) {
    $("body").removeClass("megamenu-service");
    var $mainLink = $(this);
    var $mainLi = $mainLink.parent();
    var $subMenu = $mainLi.find("ul.sub-menu");
    var $menuDescription = $mainLi.attr("data-menu-description");
    var $menuImage = $mainLi.attr("data-menu-image");

    if (!$mainLi.hasClass("menu-item-has-children")) return true;

    event.preventDefault();

    $(".page-header__megamenu__left-wrap__headline-column h3").html(
      $mainLink[0].innerHTML
    );
    $(".page-header__megamenu__left-wrap__column-wrap").html(
      $subMenu[0].outerHTML
    );

    $(".page-header__megamenu__right-wrap__image img").attr("src", $menuImage);

    $(".page-header__megamenu__right-wrap__description p").text(
      $menuDescription
    );
    // difference is below (solve with if parent ul has class primary = x if parent ul has class service = y)
    if ($mainLi.closest("div").hasClass("page-header__primary-nav")) {
      $("body").addClass("megamenu-open");
    } else {
      $("body").addClass("megamenu-open megamenu-service");
    }
  });

  //Close Megamenu
  $("#close-megamenu").click(function (event) {
    event.preventDefault();
    $("body").removeClass("megamenu-open megamenu-service");
  });

  $(window).resize(function () {
    if ($(window).width() < 1024) {
      $("body").removeClass("megamenu-open megamenu-service");
    }
    if ($(window).width() > 1023) {
      $("body").removeClass("navigation-open");
    }
  });

  // Dropdown toogle on mobile

  $(".menu-item-has-children a ~ span").click(function (e) {
    this.nextElementSibling.classList.toggle("show");
    this.parentNode.classList.toggle("active");
    e.stopPropagation();
  });

  // Second level dropdown toggle on mobile
  $(".sub-menu .menu-item-has-children a").click(function (e) {
    this.nextElementSibling.classList.toggle("show");
    this.parentNode.classList.toggle("active");
    e.stopPropagation();
  });

  $(".product-overview").each((index, item) => {
    new ProductOverview($(item));
  });
});

$(function () {
  //caches a jQuery object containing the header element
  var body = $("body");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    let scrollTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = scrollTop / (docHeight - winHeight);
    let scrollPercentRounded = Math.round(scrollPercent * 100);

    if (scroll >= 50) {
      body.removeClass("is-not-scrolled").addClass("is-scrolled");
      // console.log("pagescroll -> header is scrolled past 50px");
    } else {
      body.removeClass("is-scrolled").addClass("is-not-scrolled");
      // console.log("pagescroll -> header is not scrolled past 50px");
    }

    if (scrollPercentRounded >= 80) {
      body.addClass("hide-toggles");
    } else {
      body.removeClass("hide-toggles");
    }
  });
});
