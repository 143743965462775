import $ from "jquery";
import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const domElements = {};

function initDomElements() {
  //  domElements.main = $(".product-single-slider");
  domElements.main = $(".product-single__related-products__items");
  if (domElements.main.length < 1) return false;
  return true;
}

function productRelatedSlider() {
  if (!initDomElements()) {
    return false;
  }

  domElements.main.slick({
    cssEase: "linear",
    dots: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 981,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

export default productRelatedSlider;
