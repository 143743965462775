import $ from 'jquery';

const domElements = {};

function initDomElements() {
  domElements.main = $('.page-header');
  if (domElements.main.length < 1) return false;
  domElements.logo = domElements.main.find('.page-header__logo')
  return true;
}

function header() {
  if (!initDomElements()) {
    return false;
  }
}

export default header;