import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import wNumb from 'wnumb/wNumb';

const domElements = {};
const filterValues = {};
const itemsPerStep = 24;
let currentStep = 1;

function productFinder() {
  if (!initDomElements()) {
    return false;
  }
  initFilters();
  filterItems();
  initShowMoreBtn();
  initResetBtn();
}

function initDomElements() {
  domElements.main = $('.product-finder');
  if (domElements.main.length < 1) return false;
  domElements.filters = domElements.main.find('.product-finder__filter');

  domElements.items = domElements.main.find('.product-list-item');
  domElements.$items = [];
  for (var i = 0, len = domElements.items.length; i < len; i += 1) {
    domElements.$items.push($(domElements.items[i]));
  }

  domElements.showMoreBtn = domElements.main.find(
      '.product-finder__show-more-btn'
  );
  domElements.emptyAdvice = domElements.main.find(
      '.product-finder__empty-advice'
  );
  domElements.resetBtn = domElements.main.find('.product-finder__reset-btn');

  return true;
}

function initFilters() {
  domElements.filters.each((index, elm) => {
    initFilter($(elm));
  });
}

function initFilter($elm) {
  const $form = $elm.find('form');
  $form.on('change', onChangeFilter);
  if ($elm.find('.product-finder__filter__range-wrap').length > 0) {
    initFilterRangeSlider($elm.find('.product-finder__filter__range-wrap'));
  }
}

function initFilterRangeSlider($elm) {
  const $sliderContainer = $elm.find('.product-finder__filter__range__slider');
  const $min = $elm.find('.product-finder__filter__range__min');
  const $max = $elm.find('.product-finder__filter__range__max');
  const suffix = $elm.find('.product-finder__filter__suffix').val();

  const format = {decimals: 0, suffix: ' ' + suffix, mark: ',', thousand: '.'};

  noUiSlider.create($sliderContainer[0], {
    start: [$min.val(), $max.val()],
    connect: true,
    tooltips: [wNumb(format), wNumb(format)],
    range: {
      min: parseFloat($min.val()),
      max: parseFloat($max.val())
    }
  });

  $sliderContainer[0].noUiSlider.on('update', (values) => {
    $min.val(values[0]);
    $max.val(values[1]);
    onChangeFilter();
  });
}

function onChangeFilter() {
  readFilterValues();
  filterItems();
}

function readFilterValues() {
  domElements.filters.each((index, elm) => {
    const $filter = $(elm);
    const filterType = $filter.data('filterType');
    switch (filterType) {
      case 'multi':
      case 'bool':
        readFilterValueCheckboxes($filter);
        break;
      case 'range':
        readFilterValueRange($filter);
    }
  });
}

function readFilterValueCheckboxes($filter) {
  const filterName = $filter.data('filterName');
  const values = [];

  $filter.find('input').each((index, input) => {
    const $input = $(input);
    if ($input.prop('checked')) {
      values.push($input.val());
    }
  });

  filterValues[filterName] = {
    type: 'checkbox',
    values: values
  };
}

function readFilterValueRange($filter) {
  const filterName = $filter.data('filterName');
  const values = [];
  $filter.find('input').each((index, input) => {
    const $input = $(input);
    values.push($input.val());
  });

  filterValues[filterName] = {
    type: 'range',
    values: values
  };
}

function resetFilters() {
  domElements.filters.each((index, elm) => {
    const $filter = $(elm);
    const filterType = $filter.data('filterType');
    switch (filterType) {
      case 'multi':
      case 'bool':
        $filter.find('input').prop('checked', false);
        break;
      case 'range':
        const defaultMin = $filter.find('.product-finder__filter__range-wrap').
            data('defaultMin');
        const defaultMax = $filter.find('.product-finder__filter__range-wrap').
            data('defaultMax');
        $filter.find('.product-finder__filter__range__min').val(defaultMin);
        $filter.find('.product-finder__filter__range__max').val(defaultMax);
        $filter.find(
            '.product-finder__filter__range__slider')[0].noUiSlider.set(
            [defaultMin, defaultMax]);
    }
  });

  onChangeFilter();
}

function filterItems() {
  let showItemsCount = 0;
  let possibleVisibleItemsCount = 0;
  let matchesCount = 0;

  for (let i = 0, len = domElements.$items.length; i < len; i += 1) {
    var $item = domElements.$items[i];

    if (possibleVisibleItemsCount > currentStep * itemsPerStep) {
      hideItem($item);
      continue;
    }

    if (isItemMatchFilters($item)) {
      possibleVisibleItemsCount += 1;
      if (showItemsCount < itemsPerStep * currentStep) {
        showItemsCount += 1;
        showItem($item);
      } else {
        hideItem($item);
      }
      matchesCount += 1;
    } else {
      hideItem($item);
    }
  }

  if (currentStep * itemsPerStep >= possibleVisibleItemsCount) {
    domElements.showMoreBtn.hide();
  } else {
    domElements.showMoreBtn.show();
  }

  if (matchesCount === 0) {
    showEmptyAdvice();
  } else {
    hideEmptyAdvice();
  }
}

function showItem($item) {
  $item.show();
}

function hideItem($item) {
  $item.hide();
}

function isItemMatchFilters($item) {
  let result = true;

  for (const filterName in filterValues) {
    const filterValue = filterValues[filterName].values;
    if (filterValue.length === 0) continue;

    switch (filterValues[filterName].type) {
      case 'checkbox':
        let itemValues = [];
        const itemValuesData = $item.attr('data-filter-' + filterName);
        if (itemValuesData) {
          if (itemValuesData.indexOf(' / ') > -1) {
            itemValues = itemValuesData.split(' / ');
          } else {
            itemValues.push(itemValuesData);
          }
        }
        let matchedOne = false;
        for (let i = 0, len = itemValues.length; i < len; i += 1) {
          var itemValue = itemValues[i] + '';
          if (filterValue.indexOf(itemValue) > -1) {
            matchedOne = true;
          }
        }
        if (matchedOne === false) {
          return false;
        }
        break;
      case 'range':
        const itemValueMin = parseFloat(
            $item.attr('data-filter-' + filterName + '_min')
        );
        const itemValueMax = parseFloat(
            $item.attr('data-filter-' + filterName + '_max')
        );

        result = false;

        if (itemValueMax === 0) {
          if (itemValueMin >= filterValue[0]) {
            result = true;
          }
        } else {
          if (Math.max(itemValueMin, filterValue[0]) <=
              Math.min(itemValueMax, filterValue[1])) {
            result = true;
          }
        }

        if (result === false) {
          return false;
        }

        break;
    }
  }
  return result;
}

function initShowMoreBtn() {
  domElements.showMoreBtn.on('click', (event) => {
    event.preventDefault();
    countUpCurrentStep();
  });
}

function countUpCurrentStep() {
  currentStep += 1;
  filterItems();
}

function showEmptyAdvice() {
  domElements.emptyAdvice.fadeIn();
}

function hideEmptyAdvice() {
  domElements.emptyAdvice.hide();
}

function initResetBtn() {
  domElements.resetBtn.on('click', (event) => {
    resetFilters();
  });
}

export default productFinder;
