import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const domElements = {};

function initDomElements() {
  domElements.main = $(".news-teaser");
  if (domElements.main.length < 1) return false;
  return true;
}

function newsTeaser() {
  if (!initDomElements()) {
    return false;
  }

  domElements.main.each((index, element) => {
    const main = $(element);
    const slider = main.find(".news-teaser__slick-wrap");
    const prevArrow = main.find(".news-teaser__prev-arrow");
    const nextArrow = main.find(".news-teaser__next-arrow");

    slider.slick({
      cssEase: "linear",
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      draggable: true,
      swipe: true,
      swipeToSlide: true,
      touchMove: true,
      accessibility: true,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 981,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
}

export default newsTeaser;
