import $ from "jquery";
import wNumb from "wnumb/wNumb";

function productFormatValue() {

  $('.product-format-value').each((index, element) => {
    const $element = $(element);
    const suffix = $element.data('suffix');
    const value = $element.text();
    const floatValue = parseFloat(value);
    const formatSettings = {decimals: 0, suffix: ' ' + suffix, mark: ',', thousand: '.'};
    const formater = wNumb(formatSettings);
    let result = formater.to(floatValue);

    if (result === false) result = value; // Fallback to original value, if format failed

    $element.text(result);
  });

}

export default productFormatValue;
