function openCookieModal() {
  $(".cookie-advice").show();
  $("html").css("overflow", "hidden");
}

function closeCookieModal() {
  $(".cookie-advice").hide();
  $("html").css("overflow", "visible");
  location.reload();
}

function turnCookieModal(nextPage) {
  if (nextPage == 2) {
    $(".cookie-advice__choice").hide();
    $(".cookie-advice__info").show();
  } else {
    $(".cookie-advice__choice").show();
    $(".cookie-advice__info").hide();
  }
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  if (exdays == 0) {
    expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  }
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie(cname, cvalue) {
  var searchingfor = getCookie(cname).split(",");
  for (var i = 0; i < searchingfor.length; i++) {
    var search = searchingfor[i];
    if (search.indexOf(cvalue) == 0) {
      return search;
    }
  }
}

function writeCookieToBody(cname) {
  var cookieValues = getCookie(cname).split(",");
  for (var i = 0; i < cookieValues.length; i++) {
    var cookieValue = cookieValues[i];
    $("body").addClass("cookiechoice__" + cookieValue);
  }
}

function cookie() {
  //check if cookiechoice is already there (essential is always set)
  if (checkCookie("cookiechoice", "essential") == "essential") {
    writeCookieToBody("cookiechoice");
    //cookiemodal stays hidden because display none
  } else {
    openCookieModal();
  }

  //If cookie contains statistics add ga code for tracking data
  if (checkCookie("cookiechoice", "statistics") == "statistics") {
  } else {
  }

  // if save button is clicked push all the checkbox values to cookie (cookie is deleted first then reset)
  $("#accept-selected").click(function () {
    var cookieSelection = [];
    setCookie("cookiechoice", "deleted", 0);

    if ($("#checkbox-essential").is(":checked")) {
      cookieSelection.push("essential");
    }

    if ($("#checkbox-preferences").is(":checked")) {
      cookieSelection.push("preferences");
    }
    if ($("#checkbox-statistics").is(":checked")) {
      cookieSelection.push("statistics");
    }
    if ($("#checkbox-marketing").is(":checked")) {
      cookieSelection.push("marketing");
    }

    //join values from array to a string and set cookie with this string
    var cookieValue = cookieSelection.join(",");
    setCookie("cookiechoice", cookieValue, 30);

    //close and reload
    closeCookieModal();
  });

  //if select all is clicked push all value to cookie
  $("#accept-all").click(function () {
    setCookie("cookiechoice", "deleted", 0);
    var cookieSelection = [
      "essential",
      "preferences",
      "statistics",
      "marketing",
    ];
    var cookieValue = cookieSelection.join(",");
    setCookie("cookiechoice", cookieValue, 30);
    closeCookieModal();
  });

  $("#cookie_details").click(function () {
    turnCookieModal(2);
  });

  $("#cookie_details_back, #cookie_details_back_top").click(function () {
    turnCookieModal(1);
  });

  $('a[href*="#cookies"]').click(function () {
    openCookieModal();
  });
}

export default cookie;
