import $ from "jquery";
import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const TEASER_ACTIVE_CLASS = "product-overview__teaser--active";

class ProductOverview {
  constructor($main) {
    this.initDomElements($main);

    this.initSlider();
    this.initTeaser();
    this.updateActiveTeaser(0);
  }

  initSlider() {
    this.domElements.slider.slick({
      dots: true,
      infinite: false,
      speed: 300,
      fade: false,
      arrows: true,
      prevArrow: this.domElements.prevBtn,
      nextArrow: this.domElements.nextBtn,
      adaptiveHeight: true,
      mobileFirst: true,
    });

    this.domElements.slider.on(
      "afterChange",
      (event, slick, currentSlide, nextSlide) => {
        this.updateActiveTeaser(currentSlide);
      }
    );

    $(window).on("orientationchange", () => {
      this.domElements.slider.slick("resize");
      setTimeout(() => {
        this.domElements.slider.slick("resize");
      }, 50);
    });
  }

  initTeaser() {
    this.domElements.teaser.on("click", (event) => {
      const $teaser = $(event.currentTarget);
      const id = $teaser.data("productI");
      this.domElements.slider.slick("slickGoTo", id);
      this.scrollToContent();
    });
  }

  updateActiveTeaser(id) {
    this.domElements.teaser.each((index, element) => {
      const $teaser = $(element);
      if ($teaser.data("productI") === id) {
        $teaser.addClass(TEASER_ACTIVE_CLASS);
      } else {
        $teaser.removeClass(TEASER_ACTIVE_CLASS);
      }
    });
  }

  scrollToContent() {
    $("html, body").animate(
      {
        scrollTop: this.domElements.main.offset().top - 110,
      },
      300
    );
  }

  initDomElements($main) {
    this.domElements = {};
    const doms = this.domElements;
    doms.main = $main;
    doms.slider = doms.main.find(".product-overview__products__items");
    doms.prevBtn = doms.main.find(".product-overview__prev-btn");
    doms.nextBtn = doms.main.find(".product-overview__next-btn");
    doms.teaserContainer = doms.main.find(".product-overview__teasers");
    doms.teaser = doms.main.find(".product-overview__teaser");
  }
}

export default ProductOverview;
