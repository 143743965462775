import $ from "jquery";
const domElements = {};

function initDomElements() {
  domElements.main = $(".product-single");
  if (domElements.main.length < 1) return false;

  return true;
}

function productSingle() {
  if (!initDomElements()) {
    return false;
  }
}

export default productSingle;
