import $ from "jquery";
import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const domElements = {};

function initDomElements() {
  domElements.main = $(".product-teaser__slick-wrap");
  if (domElements.main.length < 1) return false;
  return true;
}

function productTeaser() {
  if (!initDomElements()) {
    return false;
  }
  domElements.main.slick({
    // cssEase: "linear",
    dots: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: $(".product-teaser__prev-arrow"),
    nextArrow: $(".product-teaser__next-arrow"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 981,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
}

export default productTeaser;
