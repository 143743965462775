import $ from "jquery";
import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const domElements = {};

function initDomElements() {
  //  domElements.main = $(".product-single-slider");
  domElements.main = $(".product-single-slider__slick-wrap");
  if (domElements.main.length < 1) return false;
  return true;
}

function productSingleSlider() {
  if (!initDomElements()) {
    return false;
  }
  domElements.main.slick({
    dots: true,
    infinite: true,
    speed: 300,
    fade: true,
    cssEase: "ease-in",
    autoplay: false,
    //    pauseOnHover: false,
    prevArrow: $(".product-single-slider__prev-arrow"),
    nextArrow: $(".product-single-slider__next-arrow"),
    arrows: true,
    adaptiveHeight: true,

    rows: 0,
  });
}

export default productSingleSlider;
