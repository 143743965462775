const GET_ALL_URL = '/wp-json/eaelighting-products/v1/product/';
const ADD_URL = '/wp-json/eaelighting-products/v1/product/%ID%';
const DELETE_URL = '/wp-json/eaelighting-products/v1/product/%ID%';
const TOGGLE_ACTIVE_CLASS = 'product-favorite-toggle--active';
const STATUS_ACTIVE_CLASS = 'product-favorite-status--active';

const domElements = {};
let ids = [];

function initDomElements() {
  domElements.favoriteToggles = $('.product-favorite-toggle');
  domElements.status = $('.product-favorite-status');
  domElements.favoriteItems = $('.product-favorites__items .product-list-item');
  domElements.favoritesContainer = $('.product-favorites');
}

function productFavorites() {
  initDomElements();
  initToggles();
  initForm();
  $.when(reqGetAll()).then(() => {
    setTogglesStatus();
  });
}

function initToggles() {
  domElements.favoriteToggles.on('click', event => {
    event.preventDefault();
    const $toggle = $(event.currentTarget);
    const id = $toggle.data('id');
    const reload = $toggle.data('reload-on-toggle-favorite');
    if ($toggle.hasClass(TOGGLE_ACTIVE_CLASS)) {
      $toggle.removeClass(TOGGLE_ACTIVE_CLASS);
      removeItemFromFavoritesList(id);
      $.when(reqDelete(id)).then(() => {
        if (reload) {
          location.reload();
        } else {
          reqGetAll();
        }
      });
    } else {
      $toggle.addClass(TOGGLE_ACTIVE_CLASS);
      $.when(reqAdd(id)).then(() => {
        if (reload) {
          location.reload();
        } else {
          reqGetAll();
        }
      });
    }
  });
}

function setTogglesStatus() {
  domElements.favoriteToggles.each((index, element) => {
    const $toggle = $(element);
    const id = $toggle.data('id');
    if (ids.indexOf(id) > -1) {
      $toggle.addClass(TOGGLE_ACTIVE_CLASS);
    } else {
      $toggle.removeClass(TOGGLE_ACTIVE_CLASS);
    }
  });
}

function reqGetAll() {
  var dfd = jQuery.Deferred();
  $.ajax(GET_ALL_URL, {method: 'GET', cache: false, type: 'json'}).
      done(data => {
        ids = data;
        updateStatus();
        dfd.resolve(data);
      });
  return dfd.promise();
}

function reqAdd(id) {
  var dfd = jQuery.Deferred();
  const url = ADD_URL.replace('%ID%', id);
  $.ajax(url, {method: 'POST'}).
      done(data => {
        dfd.resolve(data);
      });
  return dfd.promise();
}

function reqDelete(id) {
  var dfd = jQuery.Deferred();
  const url = DELETE_URL.replace('%ID%', id);
  $.ajax(url, {method: 'DELETE'}).
      done(data => {
        dfd.resolve(data);
      });
  return dfd.promise();
}

function updateStatus() {
  if (domElements.status.length < 1) {
    return;
  }

  if (ids.length > 0) {
    domElements.status.addClass(STATUS_ACTIVE_CLASS);
    domElements.status.data('favoritesCount', ids.length);
  } else {
    domElements.status.removeClass(STATUS_ACTIVE_CLASS);
    domElements.status.data('favoritesCount', false);
  }
}

function removeItemFromFavoritesList(id) {

  if (domElements.favoriteItems.length < 1) {
    return;
  }

  domElements.favoriteItems.each((index, element) => {
    const $listItem = $(element);
    if ($listItem.data('id') === id) {
      $listItem.hide();
    }
  });

  initForm();
}

function initForm() {
  if (domElements.favoritesContainer.length < 1) {
    return;
  }

  jQuery( document ).on( 'nfFormSubmitResponse', function(event) {
    //Do Stuff
  });

  let $field;
  const intervalHandler = setInterval(() => {
    $field = $('.form-productname input');
    if ($field.length > 0) {
      clearInterval(intervalHandler);
      setTimeout(() => {
        setProductsToFormField($field);
      }, 3000)
    }
  }, 100);
}

function setProductsToFormField($field) {
  const titles = [];

  domElements.favoriteItems.each((index, item) => {
    const $item = $(item);
    const title = $item.data('title');
    titles.push(title);
  });

  $field.val(titles.join(", ")).keypress();
//  $field.focus().val(titles.join(", ")).change().keypress().input().trigger('input').css('border', 'solid 1px red');
}



export default productFavorites;
