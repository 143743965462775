import $ from "jquery";
import "slick-carousel";
import slickCss from "slick-carousel/slick/slick.css";

const domElements = {};

function initDomElements() {
  domElements.main = $(".hero-slider__slick-wrap");
  if (domElements.main.length < 1) return false;
  return true;
}

function heroSlider() {
  if (!initDomElements()) {
    return false;
  }
  domElements.main.slick({
    dots: false,
    infinite: true,
    speed: 800,
    fade: true,
    cssEase: "ease-in",
    autoplay: true,
    autoplaySpeed: 500000,
    pauseOnHover: false,
    arrows: true,
    prevArrow: $(".hero-slider__prev-arrow"),
    nextArrow: $(".hero-slider__next-arrow"),
    rows: 0,
  });
}

export default heroSlider;
